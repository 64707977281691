import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Explainshell: westack {
      tool(id: "explainshell") {
        ...ToolInfo
      }
    }
    Microbundle: westack {
      tool(id: "microbundle") {
        ...ToolInfo
      }
    }
    Utopla: westack {
      tool(id: "concrete-utopia-utopia") {
        ...ToolInfo
      }
    }
    NuShell: westack {
      tool(id: "nushell") {
        ...ToolInfo
      }
    }
    TSDX: westack {
      tool(id: "tsdx") {
        ...ToolInfo
      }
    }
    Ripgrep: westack {
      tool(id: "ripgrep") {
        ...ToolInfo
      }
    }
    Wechaty: westack {
      tool(id: "wechaty") {
        ...ToolInfo
      }
    }
    Pose: westack {
      tool(id: "pose-animator") {
        ...ToolInfo
      }
    }
    novelwriter: westack {
      tool(id: "novelwriter") {
        ...ToolInfo
      }
    }
    Naive: westack {
      tool(id: "naive-ui") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Explainshell.tool} mdxType="Tool" />
    <Tool tool={props.data.Microbundle.tool} mdxType="Tool" />
    <Tool tool={props.data.Utopla.tool} mdxType="Tool" />
    <Tool tool={props.data.NuShell.tool} mdxType="Tool" />
    <Tool tool={props.data.TSDX.tool} mdxType="Tool" />
    <Tool tool={props.data.Ripgrep.tool} mdxType="Tool" />
    <Tool tool={props.data.Wechaty.tool} mdxType="Tool" />
    <Tool tool={props.data.Pose.tool} mdxType="Tool" />
    <Tool tool={props.data.novelwriter.tool} mdxType="Tool" />
    <Tool tool={props.data.Naive.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      